import React from "react";
import Dialog from "@mui/material/Dialog";
import {Box, Typography} from "@mui/material";
import {FormikHelpers} from "formik";
import {BusDetails, OdometerReadingWithBusId} from "../../../../../API/bus/types";
import ReadingForm, {ReadingFormData} from "../../../../../features/Form/ReadingForm/ReadingForm";
import {validateReadingWithPrevious} from "../../../../../features/Form/ReadingForm/utils";


export interface EditReadingDialogData {
    bus: BusDetails;
    reading: OdometerReadingWithBusId;
}

interface EditReadingDialogProps {
    dialogData: EditReadingDialogData;
    handleCloseDialog: () => void;
    handleSave: (originalReading: OdometerReadingWithBusId, formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => void;
}

const EditReadingDialog = ({dialogData, handleCloseDialog, handleSave}: EditReadingDialogProps) => {
    const handleSubmit = (formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => {
        if (!validateReadingWithPrevious(dialogData.bus.odometerReadings.filter(reading => reading.id !== dialogData.reading.id), formData)) {
            helpers.setSubmitting(false);
            return;
        }

        handleSave(dialogData.reading, formData, helpers);
    };

    return (
        <Dialog open={true} onClose={handleCloseDialog} maxWidth="md">
            <Box p={{xs: 2, sm: 3}}>
                <Typography variant="h5" pb={1}>{dialogData.reading.fuelType ? 'Tankimise' : 'Odomeetri näidu'} muutmine</Typography>
                {dialogData &&
                    <ReadingForm
                        bus={{fuelTypes: dialogData.reading.fuelType ? [dialogData.reading.fuelType] : []}}
                        includeRefuelling={!!dialogData.reading.fuelType}
                        copiedData={dialogData.reading}
                        handleSubmit={handleSubmit}
                        handleCloseDialog={handleCloseDialog}
                    />
                }
            </Box>
        </Dialog>
    );
};

export default EditReadingDialog;
