import React, {useEffect, useMemo, useState} from "react";
import {Alert, Box, Typography} from "@mui/material";
import NumberTextField from "../../../../../../components/Form/NumberTextField/NumberTextField";
import {Form, Formik, FormikHelpers, FormikProps} from "formik";
import Select from "../../../../../../components/Form/Select/Select";
import {Fuel, OdometerReadingType, ReadingDisplayType, readingDisplayTypeValues} from "../../../../../../API/bus/types";
import Button from "../../../../../../components/Button/Button";
import {getFuelTranslationFromStr} from "../../../../../../utils/enumTranslations";
import {isEqual} from "lodash";
import {Clear} from "@mui/icons-material";
import {
    getDateTimeString,
    getDayjsFromDateAndTime,
    getDisplayDateTime,
    getDisplayTimeRangeFromDateTimeStr,
    isSameDate
} from "../../../../../../utils/dateUtils";
import {
    OdometerAndRefuellingData,
    odometerReadingValidationSchema,
    refuellingValidationSchema
} from "../../../../../../features/Form/ReadingForm/ReadingForm";
import {useAppSelector} from "../../../../../../hooks";
import {selectAllBuses} from "../../../../../../store/busSlice";
import SelectWithId from "../../../../../../components/Form/Select/SelectWithId";
import * as Yup from 'yup';
import {BusUsageWithDistance, BusUsageWithOdometerReadings} from "../../../../dashboard/DriverDashboard/types";
import {AddReadingDialogData} from "../dialogs/AddReadingDialog";
import ControlledSelect from "../../../../../../components/Form/Select/ControlledSelect";
import dayjs, {Dayjs} from "dayjs";
import {selectResourceNameByTypeNullable} from "../../../store/selectors";
import TimePickerWithModifier from "../../../../../../components/Form/DateTimePicker/TimePickerWithModifier";
import {WorkSheetDetails} from "../../../../../../API/workSheets/types";
import {isItTheSameBusUsage} from "../../utils";
import {findPreviousReading} from "../../../../../../features/Form/ReadingForm/utils";


const driverIdValidation = Yup.object({
    driverId: Yup.number()
        .test('is-not-zero', 'Juhi valimine on kohustuslik', (value) => value !== 0),
});

export const readingValidation = odometerReadingValidationSchema.concat(driverIdValidation);
export const readingWithFuelValidation = refuellingValidationSchema.concat(driverIdValidation);

const findBusUsageByTimestamp = (busUsages: BusUsageWithOdometerReadings[], busUsageTimestamp: number) =>
    busUsages.find(usage => dayjs(usage.startDateTime).unix() === busUsageTimestamp);

const getDateTimeAndModifier = (selectedType: ReadingDisplayType, usage: {startDateTime: string, endDateTime: string}, workSheet: WorkSheetDetails) => {
    const dateTime = dayjs(selectedType === 'Lõppnäit' ? usage.endDateTime : usage.startDateTime);
    const isOnNextDay = !isSameDate(dateTime, workSheet.startDate);

    return {dateTime, isOnNextDay};
};

export interface AddReadingFormData {
    busUsageTimestamp: number;
    driverId: number;
    type: OdometerReadingType | null;
    dateTime: Dayjs;
    isOnNextDay: boolean;
    reading: string;
    fuelType: string | null;
    fuelAmount: string;
}

const getInitialValues = (
    dialogData: AddReadingDialogData,
    firstBusUsage: BusUsageWithDistance,
    selectedType: ReadingDisplayType,
): AddReadingFormData => ({
    busUsageTimestamp: dayjs(firstBusUsage.startDateTime).unix(),
    driverId: dialogData.driverId ?? 0,
    type: OdometerReadingType.START,
    ...getDateTimeAndModifier(selectedType, firstBusUsage, dialogData.workSheet),
    reading: '',
    fuelType: null,
    fuelAmount: '',
});

export interface AddReadingFormProps extends Omit<OdometerAndRefuellingData, 'includeRefuelling'> {
    dialogData: AddReadingDialogData;
    firstBusUsage: BusUsageWithDistance;
    handleSubmit: (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>, selectedBusUsage: BusUsageWithDistance) => void;
    handleCloseDialog?: () => void;
}

const AddReadingForm = ({
    dialogData,
    firstBusUsage,
    handleSubmit,
    handleCloseDialog,
}: AddReadingFormProps) => {
    const buses = useAppSelector(selectAllBuses);
    const resourceName = useAppSelector(state => selectResourceNameByTypeNullable(state, dialogData.workSheet?.resourceId, dialogData.workSheet?.resourceType));
    const [selectedType, setSelectedType] = useState<ReadingDisplayType>('Algnäit')
    const [initialValues, setInitialValues] = useState<AddReadingFormData>(getInitialValues(dialogData, firstBusUsage, selectedType));

    const showTimeSelect = useMemo(() => selectedType === 'Tankimine' || selectedType === 'Vahenäit', [selectedType]);

    const busUsagesWithReadings: BusUsageWithOdometerReadings[] = useMemo(() =>
        dialogData.busUsages.map(busUsage => ({
            ...busUsage,
            odometerReadings: dialogData.odometerReadings.filter(reading => reading.busUsage && isItTheSameBusUsage(reading.busUsage, busUsage))
        }))
    , [dialogData.odometerReadings, dialogData.busUsages]);

    const usedBuses = useMemo(() => {
        const usedBusIds = dialogData.busUsages.map(busUsage => busUsage.busId);
        return buses.filter(bus => usedBusIds.indexOf(bus.id) > -1);
    }, [dialogData.busUsages, buses]);

    useEffect(() => {
        setInitialValues(getInitialValues(dialogData, firstBusUsage, selectedType));
    }, [selectedType]);

    const areInitialValuesEqualToValues = (formikProps: FormikProps<AddReadingFormData>): boolean => isEqual(formikProps.initialValues, formikProps.values);

    const handleTypeSelect = (value: ReadingDisplayType) => {
        if (selectedType !== value) setSelectedType(value);
    };

    const handleSubmitClick = (formData: AddReadingFormData, helpers: FormikHelpers<AddReadingFormData>) => {
        const selectedBusUsage = findBusUsageByTimestamp(busUsagesWithReadings, formData.busUsageTimestamp);

        if (selectedBusUsage) handleSubmit(formData, helpers, selectedBusUsage);
    };

    return (
        <Box sx={{my: 1, width: '400px', maxWidth: '100%'}}>
            <Typography variant="h5">{selectedType === 'Tankimine' ? 'Tankimise' : 'Odomeetri näidu'} lisamine</Typography>
            {dialogData.workSheet && resourceName &&
				<Typography variant="body2" color="text.secondary" mt={-0.5} pb={1}>
                    {resourceName} | {dayjs(dialogData.workSheet.startDate).format('DD. MMMM')}
				</Typography>
            }
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmitClick}
                validationSchema={selectedType === 'Tankimine' ? readingWithFuelValidation : readingValidation}
            >
                {(formikProps: FormikProps<AddReadingFormData>) => {
                    const selectedUsage = useMemo(() =>
                        findBusUsageByTimestamp(busUsagesWithReadings, formikProps.values.busUsageTimestamp)
                    , [formikProps.values.busUsageTimestamp, busUsagesWithReadings]);

                    const selectedBus = useMemo(() =>
                        buses.find(bus => bus.id === selectedUsage?.busId)
                    , [selectedUsage]);

                    const readingsOfTheSelectedBus = useMemo(() =>
                        dialogData.workSheet.odometerReadings.filter(reading => reading.busId === selectedBus?.id)
                    , [dialogData.workSheet.odometerReadings, selectedBus]);

                    const prevReading = useMemo(() =>
                        formikProps.values.dateTime && formikProps.values.dateTime.isValid()
                            ? findPreviousReading(readingsOfTheSelectedBus, getDateTimeString(formikProps.values.dateTime))
                            : undefined
                    , [readingsOfTheSelectedBus, formikProps.values.dateTime]);

                    const existingReading = useMemo(() => {
                        if (selectedType === 'Algnäit') return selectedUsage?.odometerReadings?.find(usage => usage.type === OdometerReadingType.START);
                        if (selectedType === 'Lõppnäit') return selectedUsage?.odometerReadings?.find(usage => usage.type === OdometerReadingType.END);
                    }, [selectedUsage, formikProps.values]);

                    useEffect(() => {
                        if (selectedUsage) {
                            switch (selectedType) {
                                case 'Algnäit':
                                    if (formikProps.values.type !== OdometerReadingType.START) void formikProps.setFieldValue('type', OdometerReadingType.START);
                                    break;
                                case 'Lõppnäit':
                                    if (formikProps.values.type !== OdometerReadingType.END) void formikProps.setFieldValue('type', OdometerReadingType.END);
                                    break;
                                case 'Vahenäit':
                                case 'Tankimine':
                                    if (formikProps.values.type) void formikProps.setFieldValue('type', null);
                                    break;
                            }

                            const {dateTime, isOnNextDay} = getDateTimeAndModifier(selectedType, selectedUsage, dialogData.workSheet);

                            if (getDateTimeString(formikProps.values.dateTime) !== getDateTimeString(dateTime)) void formikProps.setFieldValue('dateTime', dateTime);
                            if (formikProps.values.isOnNextDay !== isOnNextDay) void formikProps.setFieldValue('isOnNextDay', isOnNextDay);
                            if (formikProps.values.reading) void formikProps.setFieldValue('reading', '');
                            if (formikProps.values.fuelAmount) void formikProps.setFieldValue('fuelAmount', '');
                            if (formikProps.values.fuelType) void formikProps.setFieldValue('fuelType', null);
                        }
                    }, [selectedType, selectedUsage]);

                    useEffect(() => {
                        const workSheetDateTime = dayjs(dialogData.workSheet.startDate);
                        if (formikProps.values.dateTime && formikProps.values.dateTime.isValid()) {
                            // if the date should be on next day and is currently not
                            if (formikProps.values.isOnNextDay && isSameDate(formikProps.values.dateTime, workSheetDateTime)) {
                                void formikProps.setFieldValue('dateTime', getDayjsFromDateAndTime(workSheetDateTime.add(1, 'd'), formikProps.values.dateTime));
                            }
                            // if the date should not be on next day and currently is on next day
                            if (!formikProps.values.isOnNextDay && !isSameDate(formikProps.values.dateTime, workSheetDateTime)) {
                                void formikProps.setFieldValue('dateTime', getDayjsFromDateAndTime(workSheetDateTime, formikProps.values.dateTime));
                            }
                        }
                    }, [formikProps.values.isOnNextDay]);

                    return (
                        <Form>
                            <SelectWithId
                                name="busUsageTimestamp"
                                label="Bussikasutus"
                                options={
                                    dialogData.busUsages.map(busUsage => ({
                                        id: dayjs(busUsage.startDateTime).unix(),
                                        name: usedBuses.find(usedBus => usedBus.id === busUsage.busId)?.licencePlateNumber ?? '',
                                        description: getDisplayTimeRangeFromDateTimeStr(busUsage.startDateTime, busUsage.endDateTime, dialogData.workSheet.startDate)
                                    }))
                                }
                            />
                            <ControlledSelect
                                label="Näidu tüüp"
                                value={selectedType}
                                onChange={(value: ReadingDisplayType) => handleTypeSelect(value)}
                                options={[...readingDisplayTypeValues]}
                                helperText={showTimeSelect
                                    ? undefined
                                    : `Näidu ajaks määratakse ${getDisplayDateTime(formikProps.values.dateTime)}`
                                }
                            />
                            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 0, sm: 0.5}}}>
                                {showTimeSelect &&
                                    <Box sx={{width: {xs: '100%', sm: '60%'}}}>
                                        <TimePickerWithModifier timeName="dateTime" modifierName="isOnNextDay" label="Aeg*" />
                                    </Box>
                                }
                                <Box sx={{width: {xs: '100%', sm: showTimeSelect ? '40%' : '100%'}}}>
                                    <NumberTextField
                                        name="reading"
                                        label="Odomeetri näit*"
                                        helperText={!showTimeSelect && !existingReading && prevReading ? `Eelnev näit ${prevReading.reading}km oli sisestatud ${getDisplayDateTime(prevReading.dateTime)}` : undefined}
                                    />
                                </Box>
                            </Box>
                            {existingReading && formikProps.values.reading &&
                                <Alert variant="outlined" severity="info">
                                    Bussikasutusel on juba {selectedType.toLowerCase()} märgitud ({existingReading.reading}km {getDisplayDateTime(existingReading.dateTime)}).
                                    Kas oled kindel, et soovid uue algnäidu salvestada?
                                </Alert>
                            }
                            {selectedType === 'Tankimine' &&
								<Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, gap: {xs: 0, sm: 0.5}}}>
									<Box sx={{width: {xs: '100%', sm: '60%'}}}>
                                        <Select name="fuelType"
                                                label="Kütuseliik*"
                                                options={selectedBus?.fuelTypes ?? []}
                                                sx={{width: '100%'}}
                                                translationFunction={getFuelTranslationFromStr}
                                                translationEnumType={Fuel}
                                        />
                                    </Box>
									<Box sx={{width: {xs: '100%', sm: '40%'}}}>
                                        <NumberTextField name="fuelAmount" label="Kütuse kogus*" decimals={2} />
                                    </Box>
                                </Box>
                            }
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                                pt: 2
                            }}>
                                {handleCloseDialog &&
                                    <Box sx={{width: {xs: '50%', sm: 'fit-content'}}} pr={1}>
                                        <Button
                                            disabled={formikProps.isSubmitting}
                                            variant="outlined"
                                            text="Loobu"
                                            onClick={handleCloseDialog}
                                            startIcon={<Clear/>}
                                        />
                                    </Box>
                                }
                                <Box sx={{width: {xs: '50%', sm: 'fit-content'}}}>
                                    <Button
                                        text="Salvesta"
                                        type="submit"
                                        disabled={(areInitialValuesEqualToValues(formikProps)) || formikProps.isSubmitting}
                                    />
                                </Box>
                            </Box>
                        </Form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default AddReadingForm;
