import {EntityId} from "@reduxjs/toolkit";
import {WorkGroupItemType} from "../../../API/workGroup/types";
import {AppDispatch, RootState} from "../../../store";
import {PlannerType} from "../types";
import {
    setDialogData,
    updateOppositeGroupMarkerColorMap,
    updateUnassignedCharterTripsStateWithMovedTrip,
    updateUnassignedTripsStateWithMovedTrip
} from "../../../store/plannerSlice";
import {
    addCharterTripWorkItem,
    addTripDefinitionWorkItem,
    addWorkItem,
    CharterTripWorkItem as WorkScheduleCharterTripWorkItem,
    TripDefinitionWorkItem as WorkScheduleTripDefinitionWorkItem
} from "../../../store/workScheduleItemSlice";
import {selectSelectedRegion} from "../../../store/regionSlice";

export const openAddOppositeGroupDialog = (
    currentGroupId: EntityId,
    itemId: EntityId,
    type: WorkGroupItemType,
) => (dispatch: AppDispatch, getState: () => RootState) => {
    const plannerType = getState().planner.plannerType;
    if (plannerType === PlannerType.WORK_GROUP) {
        if (type === WorkGroupItemType.TRIP_DEFINITION) {
            dispatch(setDialogData({
                addOppositeWorkGroupToTrip: {workGroupId: currentGroupId, tripId: itemId}
            }));
        } else {
            dispatch(setDialogData({
                addOppositeWorkGroupToActivity: {workGroupId: currentGroupId, activityId: itemId}
            }));
        }
    }
    if (plannerType === PlannerType.WORK_SHEET) {
        dispatch(setDialogData({
            addOppositeWorkSheetToWorkItem: {workSheetId: currentGroupId, workItemId: itemId}
        }));
    }
};

export const addOtherRegionUnassignedWorkItem = (
    tripWorkItem: WorkScheduleTripDefinitionWorkItem | WorkScheduleCharterTripWorkItem,
) => (dispatch: AppDispatch, getState: () => RootState) => {
    const selectedRegion = selectSelectedRegion(getState());
    if (!selectedRegion) { return }

    if ('charterTripId' in tripWorkItem) {
        dispatch(addCharterTripWorkItem(tripWorkItem));
        if (tripWorkItem.workItemId) {
            dispatch(addWorkItem({
                ...tripWorkItem,
                id: tripWorkItem.workItemId,
                charterTripId: tripWorkItem.id,
                type: WorkGroupItemType.TRIP_DEFINITION,
            }));
        }
        if (tripWorkItem.workSheetsOfOppositeType) dispatch(updateOppositeGroupMarkerColorMap(tripWorkItem.workSheetsOfOppositeType));
        dispatch(updateUnassignedCharterTripsStateWithMovedTrip({
            movedCharterTrip: {
                id: tripWorkItem.id,
                regionId: tripWorkItem.regionId,
            },
            selectedRegionId: selectedRegion.id,
        }));
    } else {
        dispatch(addTripDefinitionWorkItem(tripWorkItem));
        if (tripWorkItem.workItemId) {
            dispatch(addWorkItem({
                ...tripWorkItem,
                id: tripWorkItem.workItemId,
                tripSegmentId: tripWorkItem.id,
                type: WorkGroupItemType.TRIP_DEFINITION,
                tripDefinitionRepetition: tripWorkItem.repetition,
            }));
        }
        if (tripWorkItem.workSheetsOfOppositeType) dispatch(updateOppositeGroupMarkerColorMap(tripWorkItem.workSheetsOfOppositeType));
        dispatch(updateUnassignedTripsStateWithMovedTrip({
            movedTrip: {
                id: tripWorkItem.id,
                regionId: tripWorkItem.regionId,
            },
            selectedRegionId: selectedRegion.id,
        }));
    }
};
