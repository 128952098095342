import React from 'react';
import {ResourceType} from "../../../../../../../API/types";
import {Link} from "@mui/material";
import {Badge, DirectionsBus} from "@mui/icons-material";
import TooltipRow from "./TooltipRow";


interface TooltipRowWithLinkProps {
    text: string;
    route: string;
    resourceType?: ResourceType | null;
    padTop?: boolean;
}

const TooltipRowWithLink = ({text, route, resourceType, padTop}: TooltipRowWithLinkProps) => {
    return (
        <TooltipRow>
            <Link
                target="_blank"
                href={route}
                color="common.white"
                sx={{display: 'flex', alignItems: 'center', pt: padTop ? 1 : 0}}
            >
                {resourceType && resourceType === ResourceType.VEHICLE &&
					<DirectionsBus sx={{fontSize: '16px', pr: 0.5}} />}
                {resourceType && resourceType === ResourceType.DRIVER &&
					<Badge sx={{fontSize: '16px', pb: 0.5, pr: 0.5}} />}
                {text}
            </Link>
        </TooltipRow>
    );
};

export default TooltipRowWithLink;
