import React, {useContext, useEffect, useState} from 'react';
import {Box, useMediaQuery} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid';
import {filterByValue, getRegionsDisplayStr} from "../../../utils/utils";
import {getRoleTranslation} from "../../../utils/enumTranslations";
import {Permission, Role} from "../../../types";
import theme from "../../../theme";
import EditUserDialog from "./components/EditUserDialog";
import {ToastContext} from "../../../contexts/ToastContext";
import DataGrid, {DEFAULT_COL_DEF_PROPS, DEFAULT_MENU_COL_DEF_PROPS} from "../../../components/DataGrid/DataGrid";
import {ApiError, Region, User, UserPermissions} from '../../../API/types';
import {
    getUsers,
    updateUserPermissions as apiUpdateUserPermissions,
    updateUserPassword as apiUpdateUserPassword,
} from "../../../API";
import {mapErrors} from "../../../utils/errorMapping";
import ActionIcon from "../../../components/Icon/ActionIcon";
import {useAppSelector} from "../../../hooks";
import {selectAllRegions} from "../../../store/regionSlice";
import ListView from "../../../layouts/ListViewWrapper";
import InputSearch from "../../../components/FilterToolbar/components/InputSearch";
import ToolbarContainer from "../../../components/FilterToolbar/components/ToolbarContainer";
import {AxiosResponse} from "axios";
import ManageUserPasswordDialog from "./components/ManageUserPasswordDialog";


const getUserRegionsStr = (allRegions: Region[], hasAllRegions: boolean, userRegionIds: number[]) =>
    hasAllRegions ? 'Kõik' : getRegionsDisplayStr(userRegionIds ?? [], allRegions);

const getUserRolesStr = (userRoles?: Role[] | null) => {
    if (!userRoles || userRoles.length === 0) return '';
    const roleOrder = Object.values(Role);
    const orderedRoles = [...userRoles].sort((a: Role, b: Role) => roleOrder.indexOf(a) < roleOrder.indexOf(b) ? -1 : 1);

    return orderedRoles.map((role: Role) => getRoleTranslation(role)).join(', ');
};

interface DisplayUser extends User {
    regionsStr: string;
    rolesStr: string;
}

export default function Users() {
    const { addToast } = useContext(ToastContext);
    const regions = useAppSelector(selectAllRegions);
    const [searchInput, setSearchInput] = useState('');
    const [users, setUsers] = useState<User[] | undefined>(undefined);
    const [rows, setRows] = useState<DisplayUser[] | undefined>(undefined);
    const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState<DisplayUser | undefined>(undefined);
    const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        loadUserRows();
    }, []);

    useEffect(() => {
        if (users && regions) {
            setRows(users.map(user => ({
                ...user,
                regionsStr: getUserRegionsStr(regions, user.allRegions, user.regionIds ?? []),
                rolesStr: getUserRolesStr(user.roles),
            })))
        }
    }, [users, regions]);

    const findUser = (id: number | string) => {
        return rows?.find(row => row.id === id);
    }

    const handleOpenDialog = (id: number | string) => {
        const user = findUser(id);
        if (user) {
            setSelectedUser(user);
            setOpenEditUserDialog(true);
        }
    };

    const handleOpenPasswordDialog = (id: number | string) => {
        const user = findUser(id);
        if (user) {
            setSelectedUser(user);
            setOpenResetPasswordDialog(true);
        }
    };

    const handleCloseEditUserDialog = () => {
        setOpenEditUserDialog(false);
        setSelectedUser(undefined);
    };

    const handleCloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
        setSelectedUser(undefined);
    };

    const loadUserRows = () => {
        getUsers()
            .then(userData => {
                setUsers(userData);
            })
            .catch((error) => {
                addToast({type: 'error', text: mapErrors(error) ?? 'Kasutajate pärimisel ilmnes viga'});
                setRows([]);
            });
    };

    const updateUserPermissions = (userId: string, userPermissions: UserPermissions) => {
        updateUser(
            userId,
            () => apiUpdateUserPermissions(userId, userPermissions),
            {
                ...userPermissions,
                regionsStr: getUserRegionsStr(regions, userPermissions.allRegions, userPermissions.regionIds ?? []),
                rolesStr: getUserRolesStr(userPermissions.roles),
            }
        );
    };

    const updateUserCredentials = (userId: string, userName: string, password: string) => {
        updateUser(
            userId,
            () => apiUpdateUserPassword(userId, userName, password),
            { userName: userName }
        );
        setSelectedUser(undefined);
    };

    const updateUser = (
        userId: string,
        apiCall: () => Promise<AxiosResponse<void, any>>,
        partial: Partial<DisplayUser>
    ) => {
        if (selectedUser && rows) {
            const selectedRowIndex = rows.findIndex(row => row.id === userId);
            if (selectedRowIndex !== undefined) {
                apiCall()
                    .then(() => {
                        const updatedRows = [...rows];
                        updatedRows[selectedRowIndex] = {...rows[selectedRowIndex], ...partial};
                        setRows(updatedRows);
                        addToast({type: 'success', text: 'Kasutaja andmed edukalt uuendatud'});
                        handleCloseResetPasswordDialog();
                        setSelectedUser(undefined);
                    })
                    .catch((error: ApiError) => {
                        addToast({type: 'error', text: mapErrors(error) ?? 'Kasutaja andmete uuendamisel ilmnes viga'});
                    });
            }
        }
    };

    const columns: GridColDef[] = [
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'userName',
            headerName: 'Kasutajanimi',
            width: isScreenSmall ? 200 : 250,
            minWidth: 150
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'displayName',
            headerName: 'Nimi',
            width: isScreenSmall ? 200 : 250,
            minWidth: 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'phoneNumber',
            headerName: 'Telefon',
            width: isScreenSmall ? 200 : 250,
            minWidth: 100,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'rolesStr',
            headerName: 'Rollid',
            width: 220,
            minWidth: 220,
        },
        {
            ...DEFAULT_COL_DEF_PROPS,
            field: 'regionsStr',
            headerName: 'Piirkonnad',
            width: 200,
            minWidth: 200,
        },
        {
            ...DEFAULT_MENU_COL_DEF_PROPS,
            minWidth: 100,
            renderCell: params =>
                <Box>
                    { (!params.row.providers.includes('microsoft.com') && params.row.driverId) &&
                        <ActionIcon type="SET_PASSWORD" id={params.row.id} handleClick={handleOpenPasswordDialog} navPermission={Permission.UpdateUserPasswords} />
                    }
                    <ActionIcon type="EDIT" id={params.row.id} handleClick={handleOpenDialog} navPermission={Permission.UpdateUsers} />
                </Box>
        }
    ];

    return (
        <ListView headerProps={{title: 'Kasutajad'}} isLoading={!rows}>
            <DataGrid
                rows={filterByValue(rows ?? [], searchInput)}
                columns={columns}
                filterToolbar={
                    <ToolbarContainer sx={{flexDirection: {xs: 'column', md: 'row'}, gap: 1}}>
                        <InputSearch value={searchInput} updateValue={setSearchInput} />
                    </ToolbarContainer>
                }
            />
            {openEditUserDialog && selectedUser &&
                <EditUserDialog
                    user={selectedUser}
                    updateUserPermissions={updateUserPermissions}
                    handleCloseDialog={handleCloseEditUserDialog}
                />
            }
            {openResetPasswordDialog && selectedUser &&
                <ManageUserPasswordDialog
                    user={selectedUser}
                    updateUserCredentials={updateUserCredentials}
                    handleCloseDialog={handleCloseResetPasswordDialog}
                />
            }
        </ListView>
    );
}
