import React from "react";
import ActionIcon from "../../../../../../../components/Icon/ActionIcon";
import {EntityId} from "@reduxjs/toolkit";
import {useAppSelector} from "../../../../../../../hooks";
import { selectPlannerType } from "../../../../../../../store/plannerSlice";
import {selectToggledResourceType} from "../../../../../../../store/viewSlice";
import {ResourceType} from "../../../../../../../API/types";
import {PlannerType} from "../../../../../types";
import {Permission} from "../../../../../../../types";


interface AddOppositeGroupButtonProps {
    currentGroupId: EntityId;
    oppositeGroupId?: number;
    onClick: () => void;
}

const AddOppositeGroupButton = ({currentGroupId, oppositeGroupId, onClick}: AddOppositeGroupButtonProps) => {
    const plannerType = useAppSelector(selectPlannerType);
    const toggledResourceType = useAppSelector(selectToggledResourceType);

    const title = `Lisa ${toggledResourceType === ResourceType.DRIVER ? 'bussi' : 'juhi'} ${plannerType === PlannerType.WORK_GROUP ? 'töögrupp' : 'sõiduleht'}`;

    return (currentGroupId !== 0 && !oppositeGroupId &&
        <ActionIcon
            type="ADD_OPPOSITE_GROUP"
            id={0}
			navPermission={Permission.UpdateWorkSchedules}
            handleClick={onClick}
            tooltipTitle={title}
            color="common.white"
            size="large"
        />
    );
};

export default AddOppositeGroupButton;
