import {
    IconButton,
    InputAdornment,
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useState} from "react";
import {useField} from "formik";

interface PasswordFieldProps extends Omit<MuiTextFieldProps, 'name'> {
    name: string;
    label: string;
}

const PasswordField = ({name, label, ...otherProps}: PasswordFieldProps) => {
    const [field, meta, helpers] = useField(name);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(prevState => !prevState);

    return (
        <MuiTextField
            id="outlined-adornment-password"
            value={field.value}
            label={label}
            type={showPassword ? 'text' : 'password'}
            size={'small'}
            error={meta.touched && !!meta.error}
            helperText={meta.touched && meta.error}
            onBlur={() => helpers.setTouched(true)}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={
                                showPassword ? 'hide the password' : 'display the password'
                            }
                            onClick={handleClickShowPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
            }}

            onChange={(event) => helpers.setValue(event.target.value)}
            {...otherProps}
        />
    );
};

export default PasswordField;