export const iconSizeTypeValues = ['small', 'medium', 'large'] as const;
export type IconSize = typeof iconSizeTypeValues[number];

export const ICON_COLOR = {
    color: 'text.secondary'
};

export const ICON_COLOR_DISABLED = {
    color: 'text.disabled'
};

export const ICON_SIZE_SMALL = {
    minHeight: '20px',
    maxHeight: '20px',
    minWidth: '20px',
    maxWidth: '20px',
};

export const ICON_SIZE_LARGE = {
    minHeight: '26px',
    maxHeight: '26px',
    minWidth: '26px',
    maxWidth: '26px',
};
