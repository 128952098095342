import React from "react";
import {FormikHelpers} from "formik";
import {OdometerReadingRequest, BusDetails} from "../../../../../API/types";
import {useAppDispatch} from "../../../../../hooks";
import {addOdometerReading, updateOdometerReading} from "../../../../../store/currentDriverWorkSheetsSlice";
import ReadingForm, {
    getOdometerReadingRequest,
    ReadingFormData,
    ReadingFormProps
} from "../../../../../features/Form/ReadingForm/ReadingForm";
import {validateReadingWithPrevious} from "../../../../../features/Form/ReadingForm/utils";
import {Alert, Box} from "@mui/material";


interface ReadingFormWrapperProps extends Omit<ReadingFormProps, 'handleSubmit'> {
    bus: BusDetails;
    handleClose: () => void;
}

const ReadingFormWrapper = ({
    bus,
    includeRefuelling,
    handleClose,
    copiedData,
    suggestedDateTime,
    suggestedReading,
    previousReading,
    suggestedType,
    showDateTimeField,
}: ReadingFormWrapperProps) => {
    const dispatch = useAppDispatch();

    const handleSubmit = (formData: ReadingFormData, helpers: FormikHelpers<ReadingFormData>) => {
        if (!validateReadingWithPrevious(bus.odometerReadings, formData)) {
            helpers.setSubmitting(false);
            return;
        }

        const data: OdometerReadingRequest = getOdometerReadingRequest(formData);

        if (copiedData) {
            dispatch(updateOdometerReading({
                busId: bus.id,
                readingId: copiedData.id,
                driverId: copiedData.driverId,
                data: data,
                handleClose
            })).then(() => helpers.setSubmitting(false));
        } else {
            dispatch(addOdometerReading({busId: bus.id, data, handleClose})).then(() => helpers.setSubmitting(false));
        }
    };

    return (
        <Box>
            {!copiedData && !includeRefuelling &&
                <Alert variant="outlined" severity="info" sx={{mt: 1.5}}>
                    Odomeetri alg- ja lõppnäit tuleb lisada vastaval sõidulehel. Siin näidu lisamine on loodud juhuks,
                    kui sõidulehel ei ole võimalik soovitud näitu lisada.
                </Alert>
            }
            <ReadingForm
                bus={bus}
                includeRefuelling={includeRefuelling}
                handleSubmit={handleSubmit}
                suggestedReading={suggestedReading}
                suggestedDateTime={suggestedDateTime}
                copiedData={copiedData}
                previousReading={previousReading}
                suggestedType={suggestedType}
                showDateTimeField={showDateTimeField}
            />
        </Box>
    );
};

export default ReadingFormWrapper;
