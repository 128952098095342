import React from 'react';
import {User} from "../../../../API/users/types";
import Dialog from '../../../../components/Dialog/Dialog';
import {Formik, FormikProps} from 'formik';
import UserCredentialsForm, {UserCredentialsFormData} from "./UserCredentialsForm";
import {Clear} from "@mui/icons-material";
import * as Yup from "yup";
import {validationSchema} from "../../../../utils/formValidation";
import {FormikHelpers} from "formik/dist/types";

interface ManageUserPasswordDialog {
    user: User;
    updateUserCredentials: (userId: string, userName: string, password: string) => void;
    handleCloseDialog: () => void;
}

const credentialsValidationSchema = Yup.object().shape({
    userName: validationSchema('Kasutajanimi').fields.shortTextFieldRequired,
    password: validationSchema('Parool').fields.firebasePasswordField
});

export default function ManageUserPasswordDialog({user, updateUserCredentials, handleCloseDialog}: ManageUserPasswordDialog) {
    const initialValues: UserCredentialsFormData = {
        userName: user.userName ?? (user.phoneNumber ? `${user.phoneNumber.replace(/^\+372/, '')}@go.ee` : ''),
        password: '',
    };

    const handleSubmit = (formData: UserCredentialsFormData, formikHelpers: FormikHelpers<UserCredentialsFormData>) => {
        if (!user.userName || confirm('Kas oled kindel, et soovid kasutaja olemasoleva parooli uuega asendada?')) {
            updateUserCredentials(user.id, formData.userName, formData.password);
        } else {
            formikHelpers.setSubmitting(false);
        }
    }

    return <Formik initialValues={initialValues} validationSchema={credentialsValidationSchema} onSubmit={handleSubmit}>
        {(formikProps: FormikProps<UserCredentialsFormData>) =>
            <Dialog
                open={true}
                onClose={handleCloseDialog}
                title={user.displayName}
                subtitle={user.userName ? 'Määra uus parool' : 'Loo parool'}
                hasFormikForm={true}
                buttons={[
                    {
                        disabled: formikProps.isSubmitting,
                        text: "Loobu",
                        color: "secondary",
                        startIcon: <Clear />,
                        onClick: handleCloseDialog,
                    },
                    {
                        disabled: formikProps.isSubmitting,
                        text: "Salvesta",
                        type: "submit"
                    }
                ]}
            >
                <UserCredentialsForm />
            </Dialog>
        }
    </Formik>;
}