import React from "react";
import {WorkItemWorkSheets} from "../../../../../API/workSheets/types";
import CardBase from "./CardBase";
import {Typography} from "@mui/material";
import {getDisplayDate} from "../../../../../utils/dateUtils";


const WorkItemCard = ({workItem}: { workItem: WorkItemWorkSheets }) => {
    return (
        <CardBase>
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                {getDisplayDate(workItem.date)}
            </Typography>
        </CardBase>
    );
};

export default WorkItemCard;
