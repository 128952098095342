import React, {useCallback, useMemo, useState} from "react";
import {Box, Collapse, FormHelperText, Typography, useTheme} from "@mui/material";
import {Add, Close, Edit} from "@mui/icons-material";
import Button from "../../Button/Button";
import {FieldArray, useField} from "formik";
import {RoutePointForm} from "../../../scenes/authenticated/trips/detailsView/types";
import {shallowEqual} from "react-redux";
import RoutePoint from "./components/RoutePoint";
import {createEmptyPoint} from "./utils";

interface RouteProps {
    name: string;
    values: RoutePointForm[];
    excludeStopsWithoutCode: boolean;
    disabled?: boolean;
}

const Route = ({name, values, excludeStopsWithoutCode, disabled}: RouteProps) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const [field, meta, helpers] = useField(name);
    
    const hasErrors = !isOpen && !!meta.error && meta.touched;

    const handleClose = () => {
        const emptyPoint = createEmptyPoint();
        const nonEmptyPoints = field.value.filter((point: RoutePointForm) => !shallowEqual(point, emptyPoint));
        void helpers.setValue(nonEmptyPoints);
        setIsOpen(false);
    };

    const handleOpen = useCallback(() => {
        if (disabled) {
            return;
        }
        if (field.value.length === 0) {
            void helpers.setValue([createEmptyPoint()]);
        }
        setIsOpen(true);
    }, [field, helpers, disabled]);

    const label = useMemo(() => {
        return values.map(routePoint => routePoint.geoPoint?.name).join(' - ');
    }, [values]);

    return (
        <FieldArray name={name} render={arrayHelpers => (
            <Box>
                <Box sx={{
                    border: '1px solid rgba(0,0,0,0.23)',
                    '&:hover': {
                        borderColor: (isOpen || hasErrors || disabled) ? undefined : 'rgba(0,0,0,0.87)',
                    },
                    borderColor: hasErrors ? theme.palette.error.main : undefined,
                    borderRadius: '4px',
                    py: 1,
                    px: 1.5,
                    my: 1,
                    mx: 0,
                    boxSizing: 'border-box',
                }} component="fieldset">
                    <Box width="100%" display={label.length > 0 || isOpen ? 'initial': 'none'}>
                        <Typography
                            color={hasErrors ? theme.palette.error.main : (disabled ? 'text.disabled' : 'text.secondary')}
                            component="h5"
                            sx={{width: 'fit-content', fontSize: '12px', px: 0.5, mt: -2.2, backgroundColor: theme.palette.common.white}}
                        >
                            Teekond
                        </Typography>
                    </Box>
                    <Box
                        display={isOpen ? 'none' : 'flex'}
                        justifyContent="space-between"
                        role="button"
                        sx={{cursor: disabled ? 'default' : 'pointer'}}
                        onClick={handleOpen}>
                        <Typography
                            color={hasErrors
                                ? theme.palette.error.main
                                : disabled ? 'text.disabled' : (label.length > 0 ? 'inital' : 'text.secondary')}
                        >
                            {label.length > 0 ? label : 'Teekond'}
                        </Typography>
                        {!disabled && <Edit sx={{color: 'text.secondary'}} />}
                    </Box>
                    <Collapse in={isOpen}>
                        <Box sx={{py: 0.5}}>
                            {values.map((_, index) => (
                                <RoutePoint key={index}
                                            index={index}
                                            name={name}
                                            excludeStopsWithoutCode={excludeStopsWithoutCode}
                                            maxIndex={values.length-1}
                                            arrayHelpers={arrayHelpers} />
                            ))}
                            <Button
                                size="small"
                                text="Lisa uus punkt"
                                onClick={() => arrayHelpers.push(createEmptyPoint())}
                                startIcon={<Add />}
                                variant="text"
                                styles={{width: {xs: 'fit-content'}}}
                            />
                            <Box sx={{mb: '-27px', mx: 'auto', width: 'fit-content', backgroundColor: theme.palette.common.white}}>
                                <Button
                                    size="small"
                                    text="Sulge teekond"
                                    onClick={handleClose}
                                    startIcon={<Close />}
                                    variant="text"
                                />
                            </Box>
                        </Box>
                    </Collapse>
                </Box>
                {hasErrors && <FormHelperText sx={{color: theme.palette.error.main, mt: -0.5, mx: 1.75}}>
                    Teekonna vormis esineb vigu
                </FormHelperText>}
            </Box>
        )} />
    );
};

export default Route;
