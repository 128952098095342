import React from 'react';
import {Delete, DirectionsBus, PersonRounded} from "@mui/icons-material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {Box, IconButton, SvgIconTypeMap, Tooltip} from "@mui/material";
import {ResourceType} from '../../../../../../../API/types';
import Visible from "../../../../../../../components/Visible/Visible";
import {Permission} from "../../../../../../../types";
import {PlannerType} from "../../../../../types";
import {useAppSelector} from "../../../../../../../hooks";
import {selectPlannerType} from "../../../../../../../store/plannerSlice";
import {selectToggledResourceType} from "../../../../../../../store/viewSlice";


const CompositeIcon = ({SecondaryIcon, name}: {
    SecondaryIcon: OverridableComponent<SvgIconTypeMap>;
    name: string;
}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <mask id={`${name}-mask`}>
                    <rect width="28" height="28" fill="white" />
                    <g transform="translate(6, 9) scale(0.58)">
                        <SecondaryIcon sx={{color: 'black'}} />
                    </g>
                </mask>
            </defs>
            <g mask={`url(#${name}-mask)`}>
                <Delete sx={{color: 'common.white'}} />
            </g>
        </svg>
    );
};

const DeleteCompositeButton = ({handleClick}: { handleClick: () => void }) => {
    const plannerType = useAppSelector(selectPlannerType);
    const resourceType = useAppSelector(selectToggledResourceType);

    return (
        <Visible permission={Permission.UpdateWorkSchedules}>
            <Tooltip
                title={`Eemalda ${resourceType === ResourceType.DRIVER ? 'juhi' : 'bussi'} ${plannerType === PlannerType.WORK_GROUP ? 'töögrupist' : 'sõidulehelt'}`}
                arrow
                placement="top"
            >
                <Box component="span">
                    <IconButton size="small" onClick={handleClick}>
                        <CompositeIcon
                            SecondaryIcon={resourceType === ResourceType.DRIVER ? PersonRounded : DirectionsBus}
                            name={`delete-activity-${resourceType}`}
                        />
                    </IconButton>
                </Box>
            </Tooltip>
        </Visible>
    );
};

export default DeleteCompositeButton;
