import {WorkSheetWorkItem} from "../../../../../API/workSheets/types";
import {ResourceType} from "../../../../../API/workGroup/types";
import {Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import routes from "../../../../../routes";
import React from "react";


const SecondaryResourceName = ({workItem, secondaryResourceType}: {
    workItem: WorkSheetWorkItem,
    secondaryResourceType: ResourceType,
}) => {
    const secondaryWorkSheet = secondaryResourceType === ResourceType.DRIVER
        ? workItem.driverWorkSheet
        : workItem.busWorkSheet;

    const resourceName = secondaryWorkSheet ? (
            secondaryWorkSheet.resourceType === ResourceType.DRIVER
                ? (secondaryWorkSheet.driver ? `${secondaryWorkSheet.driver.firstName} ${secondaryWorkSheet.driver.lastName}` : '')
                : (secondaryWorkSheet.bus ? secondaryWorkSheet.bus.licencePlateNumber : '')
        ) : '';

    if (secondaryWorkSheet) {
        return <Link
            component={RouterLink}
            to={routes.AUTHENTICATED.WORK_SHEETS.DETAILS.replace(':id', secondaryWorkSheet.id.toString())}>
            {resourceName}
        </Link>;
    }

    return <>{resourceName}</>;
};

export default SecondaryResourceName;