import {createTheme} from '@mui/material/styles';
import { etEE } from '@mui/material/locale';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            light: '#00bcd4',
            main: '#0092df',
            dark: '#428bca',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#5cc500',
            main: '#31a100',
            dark: '#187d00'
        }
    },
    typography: {
        allVariants: {
            fontFamily: '"Open Sans", sans-serif',
            color: 'rgba(0,0,0,0.9)'
        },
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.6)'
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '13px',
                },
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.text.secondary,
                }),
            },
        },
    },
}, etEE);

export default theme;
