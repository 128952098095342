import React from "react";
import {Alert, Box, List, ListItem, ListItemIcon, Typography} from "@mui/material";
import {WorkItemWorkSheets} from "../../../../API/workSheets/types";
import {getDisplayDate, getDisplayNullableDate} from "../../../../utils/dateUtils";
import NavLink from "../../../../components/NavLink/NavLink";
import routes from "../../../../routes";


export interface BlockingItemsProps {
    items: WorkItemWorkSheets[];
    newStartDate: string | null;
}

const BlockingItemsSection = ({items, newStartDate}: BlockingItemsProps) => {
    if (items.length === 0) return null;

    return (
        <Box>
            {items.length > 0 &&
				<Alert variant="outlined" severity="warning" sx={{mb: 2}}>
					Algne reis on uuest alguskuupäevast {getDisplayNullableDate(newStartDate)} hilisematel sõidulehtedel juba planeeritud.
				</Alert>
            }
            <Typography>Jätkamiseks eemalda palun reis:</Typography>
            <List sx={{pl: 1, pt: 0.5}}>
                {items.map(workItem =>
                    <ListItem key={workItem.id} disablePadding>
                        <ListItemIcon sx={{minWidth: '10px'}}> - </ListItemIcon>
                        <Typography>
                            {getDisplayDate(workItem.date)}
                            {workItem.driverWorkSheetId &&
								<NavLink
									id={workItem.driverWorkSheetId.toString()}
									value="juhi sõidulehelt"
									route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
									openInNewWindow
									sx={{mx: 0.5}}
								/>
                            }
                            {workItem.driverWorkSheetId && workItem.busWorkSheetId ? 'ja' : ''}
                            {workItem.busWorkSheetId &&
								<NavLink
									id={workItem.busWorkSheetId.toString()}
									value="bussi sõidulehelt"
									route={routes.AUTHENTICATED.WORK_SHEETS.DETAILS}
									openInNewWindow
									sx={{mx: 0.5}}
								/>
                            }
                        </Typography>
                    </ListItem>
                )}
            </List>
        </Box>
    );
};

export default BlockingItemsSection;
