import React from "react";
import Box from "@mui/material/Box";
import TextField from "../../../../components/Form/TextField/TextField";
import PasswordField from "./PasswordField";

export interface UserCredentialsFormData {
    userName: string;
    password: string;
}

const UserCredentialsForm = () => {
    return <Box sx={{display: 'flex', flexDirection: 'column', gap: 0.5}}>
        <TextField
            name={'userName'}
            label="Kasutajanimi"
            autoComplete={'off'}
        />
        <PasswordField
            name={'password'}
            label={'Parool'}
            autoComplete={'off'}
        />
    </Box>
}

export default UserCredentialsForm;