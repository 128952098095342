import React from 'react';
import {MenuItem, TextField as MuiTextField} from '@mui/material';


interface SelectProps<T> {
    label: string;
    value: T;
    onChange: (value: T) => void;
    options: T[];
    helperText?: string;
}

const ControlledSelect = ({label, value, onChange, options, helperText}: SelectProps<any>) => {
    return (
        <MuiTextField
            value={value}
            onChange={(event) => onChange(event.target.value)}
            helperText={helperText}
            select
            size="small"
            label={label}
            sx={{width: '100%', my: 1}}
        >
            {options.map(option =>
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            )}
        </MuiTextField>
    );
};

export default ControlledSelect;
