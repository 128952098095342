import NavLink from "../../../../components/NavLink/NavLink";
import {Permission} from "../../../../types";
import React from "react";
import {Box, SxProps} from "@mui/material";

export interface FormattedNavLinkProps {
    id: string | number;
    value?: string;
    route: string;
    permission: Permission;
    isAuthorized: boolean;
}

export const FormattedNavLink = ({id, value, route, permission, isAuthorized}: FormattedNavLinkProps) => {
    const displayValue = value ?? 'määramata';
    const sx: SxProps = {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontStyle: value ? undefined : 'italic',
        opacity: value ? undefined : 0.5,
    };

    if (!isAuthorized) {
        return <Box component="span" title={displayValue} sx={sx}>{displayValue}</Box>;
    }

    return <NavLink
        id={`${id}`}
        value={value ?? 'määramata'}
        route={route}
        navPermission={permission}
        openInNewWindow={true}
        sx={sx}
    />;
};
