import React, {useCallback, useMemo, useState} from 'react';
import {useField} from "formik";
import {Autocomplete, Box, SxProps, TextField as MuiTextField, Typography,} from '@mui/material';
import {GeoPoint} from "../../../API/types";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {addGeoPoint, selectAllGeoPoints} from "../../../store/geoPointSlice";
import {getDisplayTimeWithModifier} from "../../../utils/dateUtils";

interface SelectGeoPointProps {
    name: string;
    label: string;
    excludeStopsWithoutCode?: boolean;
    sx?: SxProps;
}

const getDescription = (geoPoint: GeoPoint) => {
    return [geoPoint.stopDescription, geoPoint.stopArea, geoPoint.authority]
        .filter(item => !!item).join(" | ");
};

export const getInputHelperText = (geoPoint: GeoPoint) => {
    return [geoPoint.stopCode, geoPoint.stopDescription, geoPoint.stopArea, geoPoint.authority]
        .filter(item => !!item).join(" | ");
};

const renderOptionSecondaryText = (option: GeoPoint) => {
    if (option.id) {
        const desc = getDescription(option);
        return desc && <Typography variant="body2" color="text.secondary">{desc}</Typography>;
    } else {
        return <Typography variant="body2" color="primary">Loo uus punkt</Typography>;
    }
};

export const renderOption = (option: GeoPoint, time?: string, isTimeOnNextDay?: boolean) => {
    return (
        <Box>
            <Box sx={{display: 'flex'}}>
                {option.name}
                {time ? <Typography color="text.secondary" pl={0.5}>{getDisplayTimeWithModifier(time, !!isTimeOnNextDay)}</Typography> : ''}
            </Box>
            {option.stopCode && <Typography variant="body2" color="text.secondary">ÜTRIS kood: {option.stopCode}</Typography>}
            {renderOptionSecondaryText(option)}
        </Box>
    );
};

const SelectGeoPoint = ({name, label, excludeStopsWithoutCode, sx}: SelectGeoPointProps) => {
    const dispatch = useAppDispatch();
    const geoPoints = useAppSelector(selectAllGeoPoints);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField<GeoPoint | null>(name);
    const [inputValue, setInputValue] = useState<string>('');

    const filterGeoPoints = useCallback((filterValue: string | GeoPoint | null): GeoPoint[] => {
        const searchString: string = (typeof filterValue === 'string') ? filterValue : (filterValue ? filterValue.name : '');
        if (searchString.length < 2) {
            return [];
        } else {
            return geoPoints
                .filter(p => excludeStopsWithoutCode ? !!p.stopCode : true)
                .filter(p => p.name.toLowerCase().startsWith(searchString.toLowerCase()));
        }
    }, [excludeStopsWithoutCode, geoPoints]);

    const options: GeoPoint[] = useMemo(() => {
        if (inputValue.length > 1) {
            const filteredGeoPoints = filterGeoPoints(inputValue);
            const selectedOpt = geoPoints.find(opt => opt.id === field.value?.id);
            const options = selectedOpt && !filteredGeoPoints.find(opt => opt.id === field.value?.id)
                ? [selectedOpt, ...filteredGeoPoints]
                : filteredGeoPoints;
            if (!filteredGeoPoints.find(o => (o.name === inputValue && !o.stopCode)) && !excludeStopsWithoutCode) {
                return [{name: inputValue}, ...options];
            } else {
                return options;
            }
        }

        return []
    }, [inputValue, geoPoints]);

    const handleAddGeoPoint = (newPoint: GeoPoint) => {
        dispatch(addGeoPoint(newPoint));
    };

    return (
        <Autocomplete
            options={options}
            value={field.value}
            freeSolo
            size="small"
            onChange={(event, val) => {
                if (typeof val === 'string') {
                    helpers.setValue({name: val}).then();
                } else {
                    if (val) {
                        handleAddGeoPoint(val);
                    }
                    helpers.setValue(val).then();
                }
            }}
            filterOptions={(x) => x}
            clearOnBlur
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={ (option) => (typeof option === 'string') ? option : option.name }
            renderInput={(params) => (
                <MuiTextField
                    {...params}
                    label={label}
                    error={meta.touched && !!meta.error}
                    helperText={(() => {
                        if (meta.touched && meta.error) {
                            return meta.error;
                        } else {
                            if (field.value) {
                                return getInputHelperText(field.value);
                            } else {
                                return '';
                            }
                        }
                    })()}
                />
            )}
            sx={{...sx, my: 1}}
            renderOption={(props, option) =>
                <Box component="li" {...props} key={`${option.id ?? 0}-${option.name}`}>
                    {renderOption(option)}
                </Box>
            }
            onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    );
};

export default SelectGeoPoint;